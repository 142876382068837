export const getUrlAndTransId=(pathName)=>{
    const splitPath = pathName.split("/");
    splitPath.shift();
    if(splitPath[0]==='identification'){
      splitPath.shift();
    }
    let trn_id=null;
    let fullPath = null;
    if((splitPath[0]==='Office' && splitPath.length===5)||(splitPath[0]==='Unit' && splitPath.length===6)||(splitPath[0]==='Floor' && splitPath.length===6)||(splitPath[0]==='Property' && splitPath.length===5)){
      fullPath=splitPath.slice(0, splitPath.length-1).join("/");
      trn_id=splitPath[splitPath.length-1];
    }
    else{
        fullPath=splitPath.slice(0, splitPath.length).join("/");
        trn_id=0;  
    }
    return {
      url: fullPath, 
      trn_id: trn_id,
      applicationType:splitPath[0]
    };
}

